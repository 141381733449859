<template>
  <div>
    <div class="card">
      <el-page-header
        @back="goBack"
        :content="type === 'detail' ? '成品转旧料详情' : '成品转旧料审核'"
      >
      </el-page-header>
    </div>
    <div style="background: white; margin-top: 15px">
      <div class="top">
        <div class="item" v-for="item in arr" :key="item.id">
          <div class="item-left">{{ item.name }}</div>
          <div class="item-right">{{ item.value }}</div>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        padding: 0 15px 30px 15px;
        margin-top: 15px;
        background: white;
        height: 450px;
        overflow-y: auto;
      "
    >
      <div style="width: 48.6%">
        <div class="tableTitle">货品</div>
        <div>
          <u-table
            :data-changes-scroll-top="false"
            ref="plTable"
            use-virtual
            :row-height="50"
            :data="old_good_list"
            showBodyOverflow="title"
            showHeaderOverflow="title"
          >
            <u-table-column
              align="center"
              type="index"
              prop="index"
              label="序号"
              width="50"
            ></u-table-column>
            <template v-for="(item, index) in rowList">
              <u-table-column
                align="center"
                :key="index"
                :prop="item.prop"
                :label="item.label"
                width="180"
              >
              </u-table-column></template
          ></u-table>
        </div>
      </div>
      <div style="padding-top: 105px; margin: 0 15px">
        <template v-for="(item, index) in old_good_list">
          <div :key="index" style="height: 42px; line-height: 42px">
            <img src="../../../../assets/images/jiantou.png" />
          </div>
        </template>
      </div>
      <div style="width: 48.6%">
        <div class="tableTitle">旧料</div>
        <div>
          <u-table
            :data-changes-scroll-top="false"
            ref="plTable"
            use-virtual
            :row-height="50"
            showBodyOverflow="title"
            showHeaderOverflow="title"
            :data="new_good_list"
          >
            <u-table-column
              align="center"
              type="index"
              prop="index"
              label="序号"
              width="50"
            >
            </u-table-column>
            <template v-for="(item, index) in oldRowList">
              <u-table-column
                align="center"
                :key="index"
                :prop="'' + item.attr_fields"
                :label="item.attr_name"
                width="180"
              >
              </u-table-column></template
          ></u-table>
        </div>
      </div>
    </div>
    <div class="btns" v-if="type === 'audit'">
      <el-button
        type="primary"
        style="margin-right: 20px"
        @click="toPass(20)"
        :loading="auditLoading"
        >审核通过</el-button
      >
      <el-button type="primary" plain @click="toFail(30)">审核不通过</el-button>
    </div>
    <el-dialog
      :visible.sync="auditVisiable"
      width="30%"
      title="审核不通过原因"
      :destroy-on-close="true"
      :before-close="cancelAudit"
    >
      <el-form
        ref="auditForm"
        :model="auditForm"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="原因说明" prop="remark">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入审核不通过原因说明"
            v-model="auditForm.remark"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitAudit('auditForm')"
            >提交</el-button
          >
          <el-button @click="cancelAudit">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>


<script>
import { details, audit } from "@/api/goods/goodsHandler/newToOld/index.js";
import { getRecycleGoodsInfoReq } from "@/api/order/offlineOrder/add";

export default {
  data() {
    return {
      auditVisiable: false,
      auditForm: {},
      arr: [
        {
          name: "成品转旧料",
          value: "",
          prop: "new_to_old_number",
        },
        {
          name: "创建时间",
          value: "",
          prop: "create_time",
        },
        {
          name: "创建人",
          value: "",
          prop: "create_user_name",
        },
        {
          name: "审核时间",
          value: "",
          prop: "audit_time",
        },
        {
          name: "审核人",
          value: "",
          prop: "audit_user_name",
        },
        {
          name: "货品总净金重(g)",
          value: "",
          prop: "before_gold_weight",
        },
        {
          name: "货品总重(g)",
          value: "",
          prop: "before_weight",
        },
        {
          name: "货品数量",
          value: "",
          prop: "total_num",
        },
        {
          name: "货品总标签价(元)",
          value: "",
          prop: "sale_price",
        },
        {
          name: "旧料总净金重(g)",
          value: "",
          prop: "after_gold_weight",
        },
        {
          name: "旧料总重(g)",
          value: "",
          prop: "after_weight",
        },
        {
          name: "旧料数量",
          value: "",
          prop: "check_number",
        },
        {
          name: "备注",
          value: "",
          prop: "remark",
        },
      ],
      info: {},
      auditLoading: false,
      new_good_list: [],
      old_good_list: [],
      rowList: [
        {
          prop: "goods_number",
          label: "货号",
        },
        {
          prop: "goods_name",
          label: "品名",
        },
        {
          prop: "goods_type_name",
          label: "品类",
        },
        {
          prop: "1711",
          label: "材质成色",
        },
        {
          prop: "1077",
          label: "净金重(g)",
        },
        {
          prop: "1081",
          label: "总重(g)",
        },
        {
          prop: "sale_price",
          label: "标签价",
        },
      ],
      oldRowList: [],
      rules: {
        remark: [
          {
            required: true,
            message: "请填写审核不通过原因",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    new_to_old_id: Number,
    type: String,
  },
  created() {
    this.getDetails();
  },
  methods: {
    /*
     * 详情数据
     */
    getDetails() {
      details({ new_to_old_id: this.new_to_old_id }).then((res) => {
        if (res.code === 1) {
          this.info = res.data.info;
          this.arr.forEach((item) => {
            item.value = res.data.info[item.prop]
              ? res.data.info[item.prop]
              : "--";
          });
          res.data.list.forEach((item) => {
            this.new_good_list.push(item.new_info);
            this.old_good_list.push(item.old_info);
          });
          this.handleOldArrList(res.data.list[0].new_info.goods_type_id);
        }
      });
    },
    /*
     *  获取表头字段
     */
    handleOldArrList(id) {
      getRecycleGoodsInfoReq(id, 10).then((res) => {
        if (res.code === 1) {
          this.oldRowList = res.data;
        }
      });
    },
    /*
     *  审核通过
     */
    toPass(status) {
      this.$confirm("确定审核通过吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 审核通过
        this.handleAudit(status);
      });
    },
    cancelAudit() {
      this.auditVisiable = false;
    },
    /*
     *  审核不通过
     */
    toFail(status) {
      this.auditVisiable = true;
      // this.$confirm("确定审核不通过吗?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   // 审核通过
      //   this.handleAudit(status);
      // });
    },
    /*
     *  提交审核失败原因
     */
    submitAudit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleAudit(30);
        }
      });
    },
    /*
     *  审核操作
     */
    handleAudit(status) {
      this.auditLoading = true;
      const data = {
        new_to_old_id: this.new_to_old_id,
        status,
      };
      if (status === 30) {
        data.remark = this.auditForm.remark;
      }
      audit(data).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: '操作成功',
          });
          this.auditLoading = false;
          this.goBack();
        }
      });
    },
    /*
     * 返回
     */
    goBack() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="less" scoped>
.tableTitle {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: white;
  font-size: 16px;
}
.top {
  display: flex;
  padding: 20px 50px 10px 50px;
  background: white;
  border-radius: 6px;
  flex-wrap: wrap;
  margin-top: 20px;
  .item {
    display: flex;
    margin-bottom: 20px;
    width: 33%;
    &-left {
      width: 130px;
      color: #909399;
      font-size: 16px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #2791ff;
        position: absolute;
        top: 10px;
        left: -15px;
      }
    }
    &-right {
      color: #565656;
      font-size: 16px;
    }
  }
}
.btns {
  padding: 20px;
  background: white;
  margin-top: 20px;
  border-top: 1px solid #e8e8e8;
  position: fixed;
  left: 84px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 9;
}
</style>