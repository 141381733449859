<template>
  <div>
    <div v-if="!addVisiable && !detailVisiable">
      <div class="card" style="display: flex">
        <div class="left">
          <el-button type="primary" @click="handleAdd" icon="el-icon-plus"
            >新增成品转旧料</el-button
          >
        </div>
        <div class="line"></div>
        <div class="middle" style="flex: 1">
          <Cond @onCond="handleCond" function_code="newToOld" />
        </div>
        <div class="right" style="display: flex">
          <TableConf function_code="newToOld" @onRowList="handleRowList" />
        </div>
      </div>
      <div
        class="table"
        style="
          border: 1px solid #ddd;
          border-radius: 6px;
          overflow: hidden;
          margin-top: 10px;
        "
        v-if="rowList.length !== 0"
      >
        <el-table
          :data="tableData"
          stripe
          height="700"
          @row-dblclick="handleDalclick"
        >
          <template v-for="(item, index) in rowList">
            <el-table-column
              v-if="item.filed_status"
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
              :filter-method="filterHandler"
            >
            </el-table-column>
            <el-table-column
              v-else
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
              <template slot-scope="scope">
                <div v-if="item.field_alias === 'goods_pic'">
                  <img
                    :src="scope.row.goods_pic"
                    v-if="scope.row.goods_pic"
                    class="image-slot"
                  />
                  <div slot="error" class="image-slot" v-else>
                    <img src="../../../../assets/images/no_img.png" />
                  </div>
                </div>
                <div v-else>
                  {{ scope.row[item.field_alias] || "--" }}
                </div>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            align="center"
            fixed="right"
            field_alias="operation"
            label="操作"
            width="180"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleChange($event, scope.row)"
                >
                  <el-option label="详情" :value="1"></el-option>
                  <el-option
                    label="审核"
                    :value="2"
                    v-if="scope.row.status === 10"
                  ></el-option>
                  <el-option label="打印" :value="3"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          :current-page="cond.page"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="$store.state.pageSizes[0]"
          :page-sizes="$store.state.pageSizes"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      title="请先选择品类"
      :visible.sync="dialogVisible"
      width="30%"
      :destroy-on-close="true"
      :before-close="handleClose"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item
          label="品类"
          label-width="100px"
          required
          prop="goods_type_id"
        >
          <!-- <el-select v-model="form.goods_type_id">
              <template v-for="item in select">
                  <el-option :label="item.goods_type_name" :value="item.goods_type_id" :key="item.goods_type_id">
                    <span :style="{marginLeft: item.span * 8 +'px'}">{{item.goods_type_name}}</span>
                  </el-option>
                </template>
            </el-select> -->
          <SelectTree
            id="select"
            :props="props"
            :options="treeData"
            :clearable="isClearable"
            :accordion="isAccordion"
            @getValue="getValue($event)"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <Add
      v-if="addVisiable"
      :goods_type_id="form.goods_type_id"
      :text="text"
      :new_to_old_id="new_to_old_id"
      @hide="handleHide"
      @getList="getNewToOldList"
    />
    <Detail
      v-if="detailVisiable"
      @hide="handleHide"
      :new_to_old_id="new_to_old_id"
      :type="type"
      @getList="getNewToOldList"
    />
  </div>
</template>

<script>
import { getList } from "@/api/goods/goodsHandler/newToOld/index.js";
import Cond from "@/components/cond/Index.vue";
import TableConf from "@/components/tableConf/Index.vue";
import Add from "./Add.vue";
import Detail from "./Detail.vue";

import { getListReq as getTypeListReq } from "@/api/goods/goodsConfig/classify";

export default {
  data() {
    return {
      text: "",
      isClearable: true, // 可清空（可选）
      isAccordion: true, // 可收起（可选）
      action: "",
      rowList: [],
      tableData: [],
      type: "detail",
      total: 0,
      cond: {
        page: 1,
        page_num: 50,
      },
      addVisiable: false,
      detailVisiable: false,
      dialogVisible: false,
      treeData: [],
      props: {
        value: "goods_type_id",
        label: "goods_type_name",
        children: "children",
      },
      rules: {
        goods_type_id: [{ required: true, message: "请选择品类" }],
      },
      form: {},
      new_to_old_id: 0,
    };
  },
  components: {
    Cond,
    TableConf,
    Add,
    Detail,
  },
  created() {
    this.getTypeList();
    this.getNewToOldList();
  },
  methods: {
    /*
     *  列表数据
     */
    getNewToOldList() {
      getList({ ...this.cond }).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    getTypeList() {
      getTypeListReq({
        type: [20],
      }).then((res) => {
        if (res.code === 1) {
          this.treeData = res.data;
        }
      });
    },
    // 取值
    getValue(value) {
      const form = { ...this.form };
      form.goods_type_id = value;
      this.form = form;
      this.$nextTick(() => {
        const dom = document
          .getElementById("select")
          .getElementsByTagName("input")[0];
        this.text = dom.value;
      });
    },

    /*
     *  检索
     */
    handleCond(cond) {
      this.cond = { ...cond, page: 1, page_num: this.cond.page_num };
      this.getNewToOldList();
    },
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    handleHide() {
      this.new_to_old_id = 0;
      this.form = {};
      this.currRow = {};
      this.addVisiable = false;
      this.detailVisiable = false;
      this.type = "detail";
      this.getNewToOldList();
    },
    /*
     *  列表双击详情
     */
    handleDalclick(row) {
      this.new_to_old_id = row.new_to_old_id;
      this.detailVisiable = true;
      this.type = "detail";
    },
    /*
     *  详情
     */
    handleChange(val, row) {
      this.new_to_old_id = row.new_to_old_id;
      if (val === 1) {
        this.detailVisiable = true;
        this.type = "detail";
      } else if (val === 2) {
        this.detailVisiable = true;
        this.type = "audit";
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 成功的操作
          this.addVisiable = true;
          this.dialogVisible = false;
        } else {
          return false;
        }
      });
    },
    /*
     * 新增
     */
    handleAdd() {
      this.dialogVisible = true;
    },
    /*
     *  页码条数
     */
    handleSizeChange(val) {
      this.cond.page_num = val;
      this.getNewToOldList();
    },
    /*
     *  分页操作
     */
    handleCurrentChange(val) {
      this.cond.page = val;
      this.getNewToOldList();
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
</style>