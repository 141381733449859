<template>
  <div class="wrapper">
    <div class="card">
      <el-page-header
        @back="goBack"
        :content="type === 'edit' ? '编辑成品转旧料' : '新增成品转旧料'"
      >
      </el-page-header>
    </div>
    <div
      class="card"
      style="margin-top: 15px; display: flex; align-items: center"
    >
      <el-button type="primary" icon="el-icon-plus" @click="handleAddHang"
        >新增行</el-button
      >
      <el-button type="primary" @click="goodVisibel = true">筛选货品</el-button>
    </div>
    <div
      style="
        display: flex;
        padding: 0 15px 30px 15px;
        background: white;
        border: 1px solid #e8e8e8;
        border-top: none;
        height: 350px;
        overflow-y: auto;
      "
    >
      <div style="width: 49%; margin-right: 10px">
        <div class="tableTitle">货品</div>
        <div>
          <div>
            <u-table
              :data-changes-scroll-top="false"
              ref="plTable"
              use-virtual
              :row-height="50"
              :data="allot_goods"
              showBodyOverflow="title"
              showHeaderOverflow="title"
              @cell-click="hanldeCellClick"
              :cell-class-name="getCellIndex"
            >
              <u-table-column
                type="index"
                label="序号"
                align="center"
                width="80"
              >
                <template slot-scope="scope">
                  {{ scope.row.index }}
                </template>
              </u-table-column>
              <template v-for="(item, index) in rowList">
                <u-table-column
                  align="center"
                  :key="index"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.prop === 'goods_number' ? 300 : 100"
                >
                  <template slot-scope="scope">
                    <template v-if="item.prop === 'goods_number'">
                      <el-input
                        @blur="
                          handleBulr(
                            $event,
                            scope.row.index,
                            scope.column.index,
                            scope.row.goods_number,
                            item.prop
                          )
                        "
                        clearable
                        autofocus="true"
                        :id="'input' + scope.row.index + scope.column.index"
                        v-model="scope.row[item.prop]"
                        :placeholder="
                          item.prop === 'goods_number' ? '请输入条码号' : ''
                        "
                        v-show="
                          coordinate.column === scope.column.index &&
                          coordinate.row === scope.row.index
                        "
                        @keyup.enter.native="
                          handleKeydown(
                            $event,
                            scope.row[item.prop],
                            item.prop,
                            scope.row.index,
                            scope.column.index,
                            scope.row.goods_number
                          )
                        "
                      >
                      </el-input>
                      <span
                        v-show="
                          scope.row.index !== coordinate.row ||
                          scope.column.index !== coordinate.column
                        "
                      >
                        {{ scope.row[item.prop] }}
                        <span
                          v-if="!scope.row[item.prop]"
                          style="color: #ccc"
                          >{{
                            item.prop === "goods_number"
                              ? "请点击输入条码号，按下Enter键结束"
                              : ""
                          }}</span
                        >
                      </span>
                    </template>
                    <template v-else>
                      {{
                        item.prop === "goods_number_type"
                          ? scope.row[item.prop] === 10
                            ? "一码一货"
                            : scope.row[item.prop] === 20
                            ? "一码多货"
                            : ""
                          : scope.row[item.prop]
                      }}
                    </template>
                  </template>
                </u-table-column>
              </template>
              <u-table-column
                align="center"
                fixed="right"
                prop="operation"
                label="操作"
              >
                <template slot-scope="scope">
                  <div class="options" style="margin: 8px 0">
                    <el-button type="text" @click="handleDele(scope.row.index)"
                      >删除</el-button
                    >
                  </div>
                </template>
              </u-table-column></u-table
            >
          </div>
        </div>
      </div>
      <!-- <div style="padding-top: 105px; margin: 0 15px">
        <template v-for="(item, index) in allot_goods">
          <div :key="index" style="height: 58px; line-height: 58px">
            <img src="../../../../assets/images/jiantou.png" />
          </div>
        </template>
      </div> -->
      <div style="width: 50.5%">
        <div class="tableTitle">旧料</div>
        <div>
          <u-table
            :data-changes-scroll-top="false"
            ref="plTable"
            use-virtual
            :row-height="50"
            showBodyOverflow="title"
            showHeaderOverflow="title"
            :data="old_allot_goods"
            :cell-class-name="getCellIndex"
          >
            <u-table-column type="index" label="序号" align="center" width="80">
              <template slot-scope="scope">
                {{ scope.row.index }}
              </template>
            </u-table-column>
            <template v-for="(item, index) in oldRowList">
              <u-table-column
                align="center"
                :key="index"
                :prop="'' + item.attr_fields"
                :label="item.attr_name"
                width="180"
              >
                <template slot-scope="scope">
                  <!-- 数值 -->
                  <el-input
                    v-if="item.type === 30 || item.type === 10"
                    clearable
                    :id="'input' + scope.row.index + scope.column.index"
                    v-model="scope.row[item.attr_fields]"
                    style="margin: 8px 0"
                    placeholder="请输入"
                  />
                  <SelectTree
                    :disabled="scope.row.goods_id ? false : true"
                    v-if="
                      item.type === 20 &&
                      item.attr_fields === 'goods_type_id' &&
                      show
                    "
                    :value="goods_type_id"
                    :props="props"
                    :options="item.val_list"
                    :clearable="isClearable"
                    :accordion="isAccordion"
                    @getValue="getValue($event)"
                  />
                  <el-select
                    v-if="
                      item.type === 20 && item.attr_fields !== 'goods_type_id'
                    "
                    v-model="scope.row[item.attr_fields]"
                  >
                    <template v-for="val in item.val_list">
                      <el-option
                        :key="val.attr_value_id"
                        :label="val.attr_value"
                        :value="val.attr_value"
                      ></el-option>
                    </template>
                  </el-select>
                </template>
              </u-table-column>
            </template>
          </u-table>
        </div>
      </div>
    </div>
    <div class="table total" style="border-top: 0px">
      <div class="total-left">合计</div>
      <div class="total-right">
        <div class="total-right-item">
          数量：<span>{{ total || "0" }}</span>
        </div>
        <div class="total-right-item">
          货品总净金重(g)：<span>{{ weight || "0.00" }}</span>
        </div>
        <div class="total-right-item">
          货品总标签价：<span>{{ sale_price || "0.0000" }}</span>
        </div>
      </div>
    </div>
    <div class="remark">
      <div style="width: 50px">备注</div>
      <el-input
        type="textarea"
        :autosize="{ minRows: 3, maxRows: 3 }"
        placeholder="请输入内容"
        width="300"
        v-model="remark"
      >
      </el-input>
    </div>
    <div class="btns">
      <el-button
        type="primary"
        style="margin-right: 20px"
        @click="handleSubmit"
        :loading="loading"
        >提交审核</el-button
      >
      <el-button type="primary" plain @click="handleCancel">取消</el-button>
    </div>
    <choose-goods
      :goodVisibel="goodVisibel"
      @close="goodVisibel = false"
      @getGoods="handleGoods"
      :formItemList="formItemList"
      :selectGoods="allot_goods"
      v-if="goodVisibel"
      search_value_type="itemValue"
    />
  </div>
</template>

<script>
import storage from "good-storage";
import chooseGoods from "@/components/goods/chooseGoods/Index.vue";
import { getRecycleGoodsInfoReq } from "@/api/order/offlineOrder/add";
import goodsReq from "@/components/goods/chooseGoods/index";
import { createOrEdit } from "@/api/goods/goodsHandler/newToOld/index.js";
import numeral from "numeral";

export default {
  data() {
    return {
      remark: "",
      type: "add",
      allot_goods: [],
      goodVisibel: false,
      coordinate: { column: -1, row: -1 },
      total: "",
      weight: "",
      sale_price: "",
      loading: false,
      rowList: [
        {
          prop: "goods_number",
          label: "货号",
        },
        {
          prop: "goods_name",
          label: "品名",
        },
        {
          prop: "goods_type_name",
          label: "品类",
        },
        {
          prop: "1711",
          label: "材质成色",
        },
        {
          prop: "1077",
          label: "净金重(g)",
        },
        {
          prop: "1081",
          label: "总重(g)",
        },
        {
          prop: "sale_price",
          label: "标签价",
        },
      ],
      allot_goods: [],
      formItemList: [
        {
          label: "品类",
          placeholder: "请填写品类",
          attr: "goods_type_id",
          type: "treeSelect",
        },
        {
          label: "货号",
          placeholder: "请填写货号",
          attr: "goods_number",
          type: "input",
        },
        {
          label: "入库单号",
          placeholder: "请填写入库单号",
          attr: "entry_number",
          type: "input",
        },
        {
          label: "盘点单号",
          placeholder: "请填写盘点单号",
          attr: "check_number",
          type: "input",
        },
        {
          label: "调拨单号",
          placeholder: "请填写调拨单号",
          attr: "allot_number",
          type: "input",
        },
        {
          label: "修改单号",
          placeholder: "请填写修改单号",
          attr: "edit_number",
          type: "input",
        },
        {
          label: "款号",
          placeholder: "请填写入款号",
          attr: "goods_style_number",
          type: "input",
        },
        {
          label: "品名",
          placeholder: "请填写入品名",
          attr: "goods_name",
          type: "input",
        },
        { type: "hierarchy_id", label: "所属门店" },
        { type: "warehouse_id", label: "仓库" },
        { type: "101", label: "款式" },
        { type: "100", label: "材质成色" },
        { type: "103", label: "品牌" },
        { type: "sale_price", label: "标签价" },
        { type: "104", label: "净金重" },
        { type: "entry_time", label: "入库时间" },
      ],
      oldRowList: [],
      old_allot_goods: [],
    };
  },
  components: {
    chooseGoods,
  },
  props: {
    goods_type_id: {
      type: Number,
    },
    text: String,
  },
  created() {
    this.allot_goods = [{}];
    this.old_allot_goods = [{}];
    this.handleOldArrList();
  },
  methods: {
    handleBulr(e, row, column, goods_number, prop) {
      if (prop === "num") {
        if (!e.target.value) {
          const coordinate = { ...this.coordinate };
          coordinate.row = row;
          coordinate.column = column;
          this.coordinate = coordinate;
          const dom = document.getElementById(
            "input" + coordinate.row + coordinate.column
          );
          this.$nextTick(() => {
            try {
              dom.focus();
            } catch (error) {
              // console.log('无法聚焦');
            }
          });
          return;
        }
        if (Number(e.target.value) > Number(this.originData[goods_number])) {
          this.$message.error("编辑不能超过原有的数量");
          const coordinate = { ...this.coordinate };
          coordinate.row = row;
          coordinate.column = column;
          this.coordinate = coordinate;
          const dom = document.getElementById(
            "input" + coordinate.row + coordinate.column
          );
          this.$nextTick(() => {
            try {
              dom.focus();
            } catch (error) {
              // console.log('无法聚焦');
            }
          });
          return;
        }
        this.coordinate = {
          column: -1,
          row: -1,
        };
        // 限制不能超过最大值
        this.handleSum();
        return;
      }
      this.coordinate = {
        column: -1,
        row: -1,
      };
    },
    handleKeydown(val, value, prop, row, column, goods_number) {
      if (!value) {
        this.$message.error("请填入货号");
        return;
      }
      // if (value > this.originData[goods_number]) {
      //   this.$message.error("编辑不能超过原有的数量");
      //   return;
      // }
      if (val.keyCode === 13) {
        if (prop === "goods_number") {
          if (!value) {
            return;
          }
          // 请求接口
          goodsReq({ goods_number: value, allot_state: 1 })
            .then((res) => {
              if (res.code === 1) {
                if (!res.data.total) {
                  this.$message.warning("查询不到该货号");
                  this.allot_goods[row - 1].goods_number = "";
                  this.old_allot_goods[row - 1].goods_number = "";
                  const coordinate = { ...this.coordinate };
                  coordinate.row = row;
                  coordinate.column = column;
                  this.coordinate = coordinate;
                  this.$nextTick(() => {
                    try {
                      const dom = document.getElementById(
                        "input" + coordinate.row + coordinate.column
                      );
                      dom.value = "";
                      dom.focus();
                    } catch (error) {
                      // console.log('无法聚焦');
                    }
                  });
                  return;
                }
                let breakFlg = false;
                if (res.data.list[0].goods_tag_id) {
                  const goods_tag_id = res.data.list[0].goods_tag_id.split(",");
                  // 不能选择的标志
                  const tagList = [
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "9",
                    "10",
                    "11",
                    "12",
                  ];
                  goods_tag_id.forEach((item) => {
                    tagList.forEach((tag) => {
                      if (item === tag) {
                        // 防止一码多货，需要判断可操作为0
                        if (!res.data.list[0].usable_count) {
                          this.$message.warning(
                            `该货品状态：${this.tag[item]}，不可操作`
                          );
                          this.allot_goods[row - 1].goods_number = "";
                          this.old_allot_goods[row - 1].goods_number = "";
                          this.handleFocus(row, column);
                          breakFlg = true;
                        }
                      }
                    });
                  });
                }
                if (breakFlg) {
                  return;
                }
                // 数据是否有重复
                let m = 0;
                this.allot_goods.forEach((item) => {
                  if (item.goods_id !== res.data.list[0].goods_id) {
                    m += 1;
                    if (m === this.allot_goods.length) {
                      this.allot_goods.splice(row - 1, 1, ...res.data.list);

                      // 新增一条空数据
                      this.allot_goods.push({});
                      this.$refs.plTable.scrollBottom();
                      this.handleSum();
                      const coordinate = { ...this.coordinate };
                      coordinate.row = row + 1;
                      coordinate.column = column;
                      this.coordinate = coordinate;
                      this.$nextTick(() => {
                        try {
                          const dom = document.getElementById(
                            "input" + coordinate.row + coordinate.column
                          );

                          dom.focus();
                        } catch (error) {
                          // console.log(error);
                        }
                      });
                    }
                  } else {
                    const coordinate = { ...this.coordinate };
                    coordinate.row = row;
                    coordinate.column = column;
                    this.coordinate = coordinate;

                    this.$nextTick(() => {
                      try {
                        const dom = document.getElementById(
                          "input" + coordinate.row + coordinate.column
                        );
                        dom.value = "";
                        dom.focus();
                      } catch (error) {
                        console.log("无法聚焦");
                      }
                    });
                    this.$message.warning("该数据已存在。");
                  }
                });
                let n = 0;
                this.old_allot_goods.forEach((item) => {
                  if (item.goods_id !== res.data.list[0].goods_id) {
                    n += 1;
                    if (n === this.old_allot_goods.length) {
                      const arr = JSON.parse(JSON.stringify(res.data.list));
                      console.log(arr);
                      this.old_allot_goods.splice(row - 1, 1, ...arr);

                      // 新增一条空数据
                      this.old_allot_goods.push({});
                    }
                  } else {
                    this.$message.warning("该数据已存在。");
                  }
                });
              }
            })
            .catch(() => {
              const coordinate = { ...this.coordinate };
              coordinate.row = row;
              coordinate.column = column;
              this.coordinate = coordinate;
              this.$nextTick(() => {
                try {
                  const dom = document.getElementById(
                    "input" + coordinate.row + coordinate.column
                  );
                  dom.value = "";
                  dom.focus();
                } catch (error) {}
              });
            });
        }
        if (prop === "num") {
          this.handleSum();
        }
        this.coordinate = {
          column: -1,
          row: -1,
        };
      }
    },
    handleDele(index) {
      this.allot_goods.splice(index - 1, 1);
      this.old_allot_goods.splice(index - 1, 1);
      this.handleSum();
    },
    /*
     * 取消
     */
    handleCancel() {
      this.$emit("hide");
    },
    getCellIndex({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex + 1;
      column.index = columnIndex;
    },
    hanldeCellClick(row, column) {
      const coordinate = { ...this.coordinate };
      coordinate.row = row.index;
      coordinate.column = column.index;
      this.coordinate = coordinate;
      this.$nextTick(() => {
        try {
          const dom = document.getElementById(
            "input" + row.index + column.index
          );

          dom.focus();
        } catch (error) {
          // console.log('无法聚焦');
        }
      });
    },
    /*
     *  获取旧料的表头字段
     */
    handleOldArrList() {
      this.coordinate = { row: 1, column: 1 };
      this.$nextTick(() => {
        try {
          const dom = document.getElementById(
            "input" + this.coordinate.row + this.coordinate.column
          );
          dom.focus();
        } catch (error) {
          // console.log('无法聚焦');
        }
      });
      getRecycleGoodsInfoReq(this.goods_type_id, 10).then((res) => {
        if (res.code === 1) {
          this.oldRowList = res.data;
        }
      });
    },
    /*
     *  提交审核
     */
    handleSubmit() {
      this.loading = true;
      this.old_allot_goods.forEach((item) => {
        if (item.goods_type_id && item.goods_type_id !== "") {
          item.goods_type_id = this.goods_type_id;
        }
      });
      this.old_allot_goods = this.old_allot_goods.filter(
        (item) => item.goods_number !== undefined
      );

      createOrEdit({ list: this.old_allot_goods, remark: this.remark })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.$message({
              type: "success",
              message: "提交审核成功",
            });
            this.goBack();
          } else {
            this.loading = false;
          }
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    goBack() {
      this.$emit("hide");
    },
    getValue(value) {
      this.handleArrList(value);
      this.$emit("onChangeValue", value);
    },
    // 聚焦
    handleFocus(row, column) {
      const coordinate = { ...this.coordinate };
      coordinate.row = row;
      coordinate.column = column;
      this.coordinate = coordinate;
      this.$nextTick(() => {
        try {
          const dom = document.getElementById(
            "input" + coordinate.row + coordinate.column
          );
          dom.value = "";
          dom.focus();
        } catch (error) {
          // console.log('无法聚焦');
        }
      });
    },
    handleAddHang() {
      this.allot_goods.push({
        index: "",
        goods_number: "",
        goods_name: "",
        101: "",
        1711: "",
        1081: "",
        sale_price: "",
      });
      this.old_allot_goods.push({
        index: "",
        goods_number: "",
        goods_name: "",
      });
    },
    handleGoods(goods) {
      this.allot_goods = [...goods, ...this.allot_goods];
      this.old_allot_goods = JSON.parse(
        JSON.stringify([...goods, ...this.allot_goods])
      );
      // 去重
      let newArr = [];
      let obj = {};
      this.allot_goods.forEach((item) => {
        const { goods_id } = item;
        if (!obj[goods_id]) {
          obj[goods_id] = true;
          const newItem = { ...item };
          newArr.push(newItem);
        }
      });
      // this.old_allot_goods.forEach((item) => {
      //   const { goods_id } = item;
      //   if (!obj[goods_id]) {
      //     obj[goods_id] = true;
      //     const newItem = { ...item };
      //     newArr.push(newItem);
      //   }
      // });
      this.allot_goods = newArr;
      this.old_allot_goods = JSON.parse(JSON.stringify(newArr));
      this.handleSum();
    },
    handleSum() {
      let total = 0;
      let weight = 0;
      let sale_price = 0;
      this.allot_goods.forEach((item) => {
        if (item.goods_id) {
          total += Number(item.num);
          if (item["104"]) {
            weight += Number(item["104"]) * item.num;
          }
          sale_price += Number(item.sale_price) * item.num;
        }
      });
      // this.old_allot_goods.forEach((item) => {
      //   if (item.goods_id) {
      //     total += Number(item.num);
      //     if (item["104"]) {
      //       weight += Number(item["104"]) * item.num;
      //     }
      //     sale_price += Number(item.sale_price) * item.num;
      //   }
      // });
      this.total = total;
      this.weight = numeral(weight).format("0.00");
      this.sale_price = numeral(sale_price).format("0.00");
    },
  },
};
</script>

<style lang="less" scoped>
.remark {
  width: 50%;
  padding: 20px;
  display: flex;
  margin-bottom: 80px;
}
.tableTitle {
  margin-top: 15px;
  height: 44px;
  line-height: 50px;
  text-align: center;
  background: white;
  font-size: 16px;
  background: linear-gradient(0deg, #f3f9ff 1%, #ffffff 100%);
  border: 1px solid #e8e8e8;
  border-bottom: none;
  border-radius: 6px 6px 0px 0px;
}
.btns {
  padding: 20px;
  background: white;
  margin-top: 20px;
  position: fixed;
  left: 84px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 9;
}
.title {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  width: 100%;
  height: 60px;
  line-height: 60px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  padding: 0 20px;
  background: white;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  &-left {
  }
  &-right {
    display: flex;
    &-item {
      font-size: 14px;
      padding: 0 10px;
      font-weight: 700;
      span {
        color: #fd563a;
        font-weight: bold;
      }
    }
  }
}
</style>