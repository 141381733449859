import { post } from '@/utils/request';

// 新品转旧料单列表
const getList = (data) => post({
    url: '/store/goods.handling.NewToOld/list',
    data: data
});

// 详情
const details = (data) => post({
    url: '/store/goods.handling.NewToOld/detail',
    data: data
});

// 新增
const createOrEdit = (data) => post({
    url: '/store/goods.handling.NewToOld/create',
    data: data
});


// 审核
const audit = (data) => post({
    url: '/store/goods.handling.NewToOld/audit',
    data: data
});


// 本地打印
const getToOldPrint = (data) => post({
    url: '/store/goods.handling.NewToOld/getToOldPrint',
    data: data
});


export {
    getList,
    details,
    createOrEdit,
    audit,
    getToOldPrint
};
